import { gql, useQuery } from "@apollo/client"
import { get } from "lodash"
import { readSettings } from "../../../reducers/globalsettings"

export const GET_GLOBALSETTINGS = gql`
  query GlobalSettings {
    globalSettings {
      items {
        activitiesColumns
        automatedExportFilePathProgresses
        automatedImportDeputy {
          id
        }
        automatedImportDeputyId
        activitiesColumnsExternal
        automatedImportResponsible {
          id
        }
        automatedImportResponsibleId
        automatedImportFilePathArticles
        automatedImportFilePathOrderPositions
        colorType
        checkPlausibility
        closeGapsDisabled
        checkActivitiesPlausibility
        defaultTermination
        donePreviousOperationsByDefault
        freeMoveTimeline
        freeMoveProductionTimeline
        importDefaultArticle {
          id
        }
        importDefaultArticleId
        importIgnoreChangedDeliveryDate
        monthsInPast
        monthsInFuture
        moveResourcesAcrossGroups
        oeeEfficiencyCalc
        operationsColumns
        oeeAvailabilityCalc
        orderPositionsColumns
        optimizeWorkloadDisabled
        operationsColumnsExternal
        planeusApiToken
        projectsColumns
        pendingByDefault
        productionOrdersColumns
        resourcePlanningItemLabel
        terminalAutomatedFeedbackParallelOperations
        weekendDays
        workbenchAmountOptional
        workbenchAutomatedLogOut
        workbenchDurationOptional
        workbenchAutomatedLogOutTime
        workloadRoundMultipleReservations
        workbenchTicketDescriptionActivities
        workbenchTicketDescriptionOperations
        anonymousProgressFeedback
        punctualityTolerance
      }
    }
  }
`

const useFetchData = () => {
  const { data: globalSettingsData, refetch } = useQuery(GET_GLOBALSETTINGS)
  const globalSettings = get(globalSettingsData, "globalSettings.items[0]", null)
  const parsedData = globalSettings ? readSettings(globalSettings) : null

  return {
    parsedData,
    refetch,
  }
}

export default useFetchData
